import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAgency from './agency.reducer';
import { BaseUser } from 'src/app/shared/models';

export const selectAgencyState = createFeatureSelector<fromAgency.AgencyState>(
  fromAgency.agencyFeatureKey
);

export const selectAgencies = createSelector(
  selectAgencyState,
  state => state
);

export const selectAgenciesByIds = (ids: string[]) =>
  createSelector(selectAgencyState, state => ({
    ...state,
    agencies: state?.agencies?.filter(agency => ids.includes(agency.id) ?? null,)
  }));

export const selectAgencyById = (id: string) =>
  createSelector(selectAgencyState, state => ({
    ...state,
    agency: state?.agencies?.find(agency => agency.id === id) ?? null,
  }));

export const selectAgencyByIdBaseUser = (id: string) =>
  createSelector<object, fromAgency.AgencyState, BaseUser | undefined>(
    selectAgencyState,
    state => state.agencies.find(agency => agency.id === id)
  );

export const selectSelectedAgency = createSelector(
  selectAgencyState,
  state => state.agency
);
