import { Injectable } from '@angular/core';
import {
  signOut,
  onAuthStateChanged,
  getIdTokenResult,
  User as AuthUser,
  ParsedToken,
  signInWithCustomToken,
  Unsubscribe,
} from '@angular/fire/auth';
import { from, Observable, ReplaySubject, Subject, take, takeWhile } from 'rxjs';
import { BaseUser, UserType, userTypeFromJSON } from '../models';
import { Auth } from '@angular/fire/auth';
import { Store } from '@ngrx/store';
import { AgentActions } from '../store/agent/+state/agent.actions';
import { AgencyActions } from '../store/agency/+state/agency.actions';
import { selectAgentById } from '../store/agent/+state/agent.selectors';
import { selectAgencyById } from '../store/agency/+state/agency.selectors';
import { Firestore, doc, onSnapshot } from '@angular/fire/firestore';
import { selectEmployeeById } from '../store/employee/+state/employee.selectors';
import { EmployeeActions } from '../store/employee/+state/employee.actions';
import { CookieService } from './cookie.service';
import { setUser as sentrySetUser } from "@sentry/angular";
import { Functions } from '@angular/fire/functions';


export interface UserInfo {
  user: AuthUser;
  claims: ParsedToken;
  type: UserType;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user$: ReplaySubject<UserInfo | null> = new ReplaySubject(1);
  userData$: ReplaySubject<any> = new ReplaySubject(1);

  user: AuthUser | null = null;
  userInfo: UserInfo | null = null;
  userData?: any;

  currentUser$ = new ReplaySubject<BaseUser>(1);
  currentUserSelection$ = new ReplaySubject<BaseUser[] | undefined>(1);

  tokenUpdateSubscription?: Unsubscribe;
  _lastClaimChange?: number;
  _currentUserSubscription?: {
    unsubscribe: () => void;
    userId: string;
  };

  constructor(
    private auth: Auth,
    private cookieService: CookieService,
    private firestore: Firestore,
    private functions: Functions,
    private store: Store,
  ) {
    if (
      this.tokenUpdateSubscription &&
      typeof this.tokenUpdateSubscription === 'function'
    ) {
      this.tokenUpdateSubscription();
    }
    this.tokenUpdateSubscription = onAuthStateChanged(auth, async (user) => {
      // console.log('onAuthStateChanged', user);

      if (user) {
        const tokenResult = await getIdTokenResult(user);
        // console.log('tokenResult', tokenResult);
        const type = userTypeFromJSON(tokenResult.claims['userType']);
        this.userInfo = { user: user, claims: tokenResult.claims, type }
        this.user$.next({ user: user, claims: tokenResult.claims, type });

        if (
          !this._currentUserSubscription ||
          this._currentUserSubscription.userId !== user.uid
        ) {
          if (
            this._currentUserSubscription &&
            typeof this._currentUserSubscription.unsubscribe === 'function'
          ) {
            this._currentUserSubscription.unsubscribe();
          }

          this._currentUserSubscription = {
            unsubscribe: onSnapshot(
              doc(this.firestore, 'users', user.uid),
              snapshot => {
                const data = snapshot.data();

                if (
                  this._lastClaimChange &&
                  data?.['lastClaimChange'] &&
                  this._lastClaimChange !== data?.['lastClaimChange']
                ) {
                  user?.getIdToken(true);
                }

                this._lastClaimChange = data?.['lastClaimChange'];
              }
            ),
            userId: user.uid,
          };
        }
      } else {
        this.userInfo = null;
        this.user$.next(null);
      }
    });


    this.user$.subscribe((user) => {
      this.user = user?.user || null;
      console.log('user', this.user?.uid, user);
      // necessary because ngrx store is not ready yet
      setTimeout(() => {
        if (user && user.user?.uid) {
          switch (user.type) {
            case UserType.AGENT:
              this.store
                .select(selectAgentById(user.user.uid))
                .subscribe((agent) => {
                  if (agent.agent) {
                    try {
                      sentrySetUser({ id: user.user.uid, username: agent.agent?.firstName + ' ' + agent.agent?.lastName });
                    } catch (e) {
                      console.error('Sentry error', e)
                    }

                    this.userData = { ...agent.agent, userType: UserType.AGENT };
                    this.userData$?.next(this.userData);
                  }
                });

              this.store.dispatch(
                AgentActions.loadAgent({ agentId: user.user.uid }),
              );
              break;
            case UserType.AGENCY:
              this.store
                .select(selectAgencyById(user.user.uid))
                .subscribe((agency) => {
                  if (agency.agency) {
                    try {
                      sentrySetUser({ id: user.user.uid, username: agency.agency?.name });
                    } catch (e) {
                      console.error('Sentry error', e)
                    }
                    this.userData = { ...agency.agency, userType: UserType.AGENCY };
                    this.userData$?.next(this.userData);
                  }
                });

              this.store.dispatch(
                AgencyActions.loadAgency({ agencyId: user.user.uid }),
              );
              break;
            case UserType.EMPLOYEE:
              this.store
                .select(selectEmployeeById(user.user.uid))
                .subscribe((employee) => {
                  if (employee.employee) {
                    try {
                      sentrySetUser({ id: user.user.uid, username: employee.employee?.firstName + ' ' + employee.employee?.lastName });
                    } catch (e) {
                      console.error('Sentry error', e)
                    }
                    this.userData = { ...employee.employee, userType: UserType.EMPLOYEE };
                    this.userData$?.next(this.userData);
                  }
                });

              this.store.dispatch(
                EmployeeActions.loadEmployee({ employeeId: user.user.uid }),
              );
              break;
          }
        }
      }, 500)
    });
  }

  myUserId = (): string | null => {
    return this.user ? this.user.uid : null;
  };

  logout(): Observable<void> {
    return from(signOut(this.auth));
  }

  async signInWithToken(token: string, currentUser?: string) {
    const r = await signInWithCustomToken(this.auth, token).catch((err) =>
      Promise.reject(err),
    );
    if (r) {
      if (currentUser) {
        try {
          console.log('currentUserbefore', JSON.parse(atob(currentUser)))
          const parsed = BaseUser.fromJSON(JSON.parse(atob(currentUser)))
          console.log('currentUser', parsed)
          if (parsed) {
            this.setCurrentUser(parsed.id, parsed);
          }
        } catch (e) {
          console.log('cannot parse current user', e)
        }
      }

      // this.navCtrl.setRoot('HomePage');
      return Promise.resolve(r);
    }
    return Promise.reject('error');
  }

  getIdToken = (): Promise<string> =>
    new Promise((resolve, reject) => {
      this.user$.pipe(takeWhile(x => !x?.user, true)).subscribe((user) => {
        if (!user?.user) {
          return;
        }

        user?.user
          ?.getIdToken()
          .then((idToken) => {
            resolve(idToken);
          })
          .catch((error) => {
            reject(error);
          });
      });
    });

  getCurrentUser = (): Promise<BaseUser> =>
    new Promise(resolve => {
      const localStorageUser = this.cookieService.get('currentUser');

      if (localStorageUser) {
        const user = BaseUser.fromJSON(localStorageUser);
        // this.setCurrentUser(user.id, user);
        this.currentUser$.next(user);

        try {
          const localStorageUserSelection = this.cookieService.get('currentUserSelection');
          if (localStorageUserSelection) {
            console.log('localStorageUserSelection', localStorageUserSelection)
            this.currentUserSelection$.next(localStorageUserSelection);
          } else {
            this.currentUserSelection$.next([user]);
          }
        } catch (e) {
          console.error('Error parsing currentUserSelection', e)
          this.currentUserSelection$.next([user]);
        }
        resolve(user);
      } else {
        this.setCurrentUser(undefined, undefined, true);
        // this.user$
        //   .pipe(takeWhile(user => !user, true))
        //   .subscribe(async user => {
        //     if (user?.user) {
        //       this.setCurrentUser(user.user.uid, undefined, true);
        //       resolve({ userType: user.type, id: user.user.uid });
        //     }
        //   });
      }
    });

  setCurrentUser = (userId?: string, user?: BaseUser, isAuthUser = false, selection?: BaseUser[]) =>
    new Promise(resolve => {
      console.log('setCurrentUser', { userId, user, isAuthUser })
      if (!isAuthUser && !user?.userType) {
        console.error('userType missing')
      }
      if (userId && user && !isAuthUser) {
        this.cookieService.set(
          'currentUser',
          BaseUser.toJSON({ ...user, id: userId })
        );
        this.cookieService.set(
          'currentUserSelection',
          selection
        );

        const baseUser = { ...user, id: userId };

        try {
          const name = this.userData?.firstName ? this.userData?.firstName + ' ' + this.userData?.firstName : this.userData?.name
          const currentUserName = baseUser?.firstName ? baseUser?.firstName + ' ' + baseUser?.firstName : baseUser?.name
          sentrySetUser({ id: `${this.user?.uid} as ${userId}`, username: `${name} as ${currentUserName}` });
        } catch (e) {
          console.error('Sentry error', e)
        }

        this.currentUser$.next(baseUser);
        this.currentUserSelection$.next(selection ?? [baseUser]);
        resolve(baseUser);
      } else {
        this.cookieService.delete('currentUser');
        this.cookieService.delete('currentUserSelection');
        this.user$
          .pipe(takeWhile(user => !user, true))
          .subscribe(async user => {
            if (user?.user) {
              const baseUser = { userType: user.type, id: user.user.uid };
              this.currentUser$.next(baseUser);
              this.currentUserSelection$.next([baseUser]);

              if (user.type === UserType.AGENT) {
                this.userData$.pipe(takeWhile(u => !u.id || !u.currentAgency?.id, true)).subscribe((userData) => {
                  this.currentUser$.next({ ...baseUser, ...userData });
                })
              }
              resolve(baseUser);
            }
          });
      }
    });

  getCurrentUserId = () => new Promise<string>((resolve) => {
    this.currentUser$.pipe(takeWhile(u => !u?.id, true)).subscribe((user) => {
      if (user?.id) {
        resolve(user.id)
      }
    })
  })

  getCurrentUserSelection = () => new Promise<BaseUser[] | undefined>(resolve => {
    this.currentUserSelection$.pipe(take(1)).subscribe(selection => {
      if (selection) {
        resolve(selection)
      } else {
        resolve(undefined)
      }
    })
  })
}