import { Injectable } from '@angular/core';
import { CookieService as NgxCookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  constructor(private cs: NgxCookieService) { }

  get(key: string) {
    const res = this.cs.get(key);

    if (res.startsWith('{') || res.startsWith('[')) {
      return JSON.parse(res);
    }
    if (res === 'undefined') {
      return undefined;
    }
    return res;
  }

  set(
    key: string,
    value: unknown,
    options: { expires?: Date; crossSubdomain?: boolean } = {}
  ) {
    const { expires, crossSubdomain } = options;
    // const currentDomain = window.location.hostname;
    this.cs.set(
      key,
      typeof value !== 'string' ? JSON.stringify(value) : value,
      {
        expires,
        path: '/'
        // domain: crossSubdomain ? currentDomain : undefined,
      }
    );
  }

  delete(key: string) {
    this.cs.delete(key, '/');
  }
}
